h4 {
  margin: 0;
}

.contact-wrap {
  height: 100%;
  color: #EFEDF2;
  background-image: linear-gradient( 
  2deg , #001E3D 19%, transparent 75%);
}

.direct-contact {
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 16px;
  font-size: 18px;
  font-weight: 500;
}

.contact-phone, .contact-email {
  margin-top: 16px;
  font-size: 17px;
  font-weight: 500;
  transition: 300ms;
}

.contact-phone:hover, .contact-email:hover {
  color: #DE3130
}

.form-container {
  margin: 16px;
  padding-bottom: 16px;
}

.hello-sent-wrap {
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 5px 0;
}

.name-fields {
  display: flex;
}

.input {
  height: 35px;
  width: 98%;
  font-size: 20px;
  background-color: #2A2A2A;
  color: #EFEDF2;
  border: none;
  border-radius: 6px;
  padding-left: 7px;
}

.input:nth-child(1) {
  margin-right:2.5%;
}

.name-fields > .input, .form-phone {
  width: 47%;
}

/* input[type='radio'],
.label-radio 
{
  margin: 0 0 16px 16px;
}

input[type='radio']:checked:after {
  width: 10px;
  height: 10px;
  border-radius: 15px;
  top: -2px;
  left: -0.5px;
  position: relative;
  background-color: #DE3130;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #001E3D;
  -webkit-text-fill-color: #EFEDF2;
  -webkit-box-shadow: 0 0 0px 1000px #001E3D inset;
  transition: background-color 5000s ease-in-out 0s;
}

.submit-button {
  border: none;
  border-radius: 6px;
  width: 100px;
  /* align-self: center; */
  background-color: #EFEDF2;
  color: #001E3D;
  font-weight: 500;
  margin-top: 16px;
}

@media screen and (min-width: 260px) and (max-width: 359px) {
  .name-fields {
    flex-direction: column;
  }

  .name-fields > .input, .form-phone {
    width: 98%;
    margin-bottom: 10px;
}
}

@media only screen and (min-width: 768px) {
  .contact-wrap {
    height: 100%;
  }

  .form-container {
    display: flex;
    flex-direction: column;
  }

  .hello-sent-wrap {
    text-align: left; 
}

  .contact-form {
    width: 1000px;
  }

  .name-fields > .input, .form-phone {
    width: 313px;
  }

  .input {
    width: 662px;
  }

}

