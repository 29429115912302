.link, a:link, a:visited {
  color: #EFEDF2;
  text-decoration: none;
}

.mobile-nav-container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  height: 100px;
}

.not-active {
  color: #6E8898;
  /* text-decoration: line-through; */
}

.burger-container {
  /* border: 1px solid orange; */
  width: 60px;
  height: 31px;
  /* position: relative; */
  margin: auto 16px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.burger-container .patty {
  display: block;
  position: absolute;
  height: 5.5px;
  width: 100%;
  background: #DE3130;
  border-radius: 8px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.burger-container .patty:nth-child(1) {
  top: 0px;
}

.burger-container .patty:nth-child(2) {
  top: 12px;
}

.burger-container .patty:nth-child(3) {
  top: 24px;
}

.nav-open {
  /* border: 1px solid blue; */
	display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #2B2B2B;
  opacity: 0.875;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 86px;
  z-index: 3;
  transition: .5s ease-in-out;
}

.nav-links {
  /* border:1px solid indigo; */
  height: 47%;
  margin: 0 0 0 16px;
  padding: 0;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  list-style-type: none;
  font-size: 1.5em;
  font-weight: 400;
}

.burger-container.open span:nth-child(1) {
  top: 18px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.burger-container.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.burger-container.open span:nth-child(3) {
  top: 18px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nav-selected {
  border-bottom: 2px solid #DE3130;
  width: fit-content;
}

.nav-socials {
  /* border: 1px solid pink; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 190px;
  margin-left: 16px;
}

.social-icon {
  width: 50px;
}

.nav-logo {
  /* border: 1px solid yellow; */
  width: 75px;
  margin: 10px;
  opacity: 0.8;
  z-index: 3;
}

.hidden {
  display: none;
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  .desktop-nav-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    height: 100px;
  }

  .nav-logo {
    width: 80px;
  }

  .burger-container {
    display: none;
  }

  .nav-open {
    /* border: 1px solid blue; */
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: initial;
    background-color: transparent;
  }

  .nav-links {
    flex-direction: row;
  }

  .nav-socials {
    height: 100%;
    width: 180px;
  }

  .social-icon {
    width: 35px;
  }

}

@media only screen and (min-width: 960px) {
  .nav-socials {
    justify-content: space-between;
    width: 240px;
  }

  .social-icon {
    width: 50px;
  }
}
