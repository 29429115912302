.services-container {
    /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
  color: #EFEDF2;
  background-image: linear-gradient( 
  2deg , #001E3D 42%, transparent 75%);
  padding: 16px;
  align-items: flex-start;
  text-align: left;
}

.services {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.list {
  list-style-type : circle;
  margin: 0;
  line-height: 1.5em;
}

.results-container {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  height: auto;
  width: 100%;
  align-items: flex-end;
}

.result-image {
  width: 60px;
}

.result-box {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
  height: 170px;
  padding: 10px;
  margin: 0 0 16px 0;
  text-align: center;
  background: linear-gradient(
    0deg, #001E3D 52%, #003062 98%);
  border-radius: 5px;
  box-shadow: 0px 0px 19px 0px #00000078;
}

.result-body {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 280px) and (max-width: 319px) {
  .result-box {
    width: 100px;
    height: 219px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
  .result-box {
    width: 116px;
    height: 187px;
  }
}

@media only screen and (min-width: 360px) {
  .services-container {
    padding: 10px;
  }

  .result-box {
    width: 142px;
  }
}

@media only screen and (min-width: 768px) {
  .services-container {
    flex-direction: row;
    padding: 16px;
  }

  .results-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 50%;
    align-items: flex-end;
  }
}