
body {
  /* background: fixed; */
  background-image: url("/Users/codebook/projects/smarter-home-integrations/src/assets/dark_sky_dan.jpg");
  background-size: 1000px;
  background-position-x: center;
}

.site-container {
  margin-inline: auto;
  height: 100vh;
}

footer, .footer {
  text-align: center;
  background-color: #001E3D;
  margin: 0;
  padding: 16px 0 5px 0;
}

@media only screen and (min-width: 768px) {
  body {
    /* height: 100vh; */
    background-size: cover;
    background-position: 0 55%;
  }

}

@-moz-document url-prefix() {
  .site-container {
    height: auto;
  }
} 

_::-webkit-full-page-media, _:future, :root .site-container {
    height:max-content
 
}
  