h1, h3, h5 {
  margin: 0;
}
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  text-align: center;
  color: #EFEDF2;
  padding: 16px;
  background-image: linear-gradient( 
2deg , #001E3D 19%, transparent 75%);
}

.home-background {
  position: fixed;
  object-fit: fill;
  z-index: -1;
  height:100%;
  bottom: 26px;
}

.values-container {
  margin-top: 30%;
  padding: 0 16px;
  text-shadow: 0 0 6px black;
}

.home-values {
  font-size: 30px;
  margin: 0 0 10px 0;
}

.home-welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50%;
  padding: 0 16px;
}

.welcome-dash {
  display: block;
  position: relative;
  height: 2px;
  width: 60px;
  background-color: #DE3130;
  border-radius: 10px;
  margin: 5px 20px;
}

.welcome-intro {
  font-size: 12px;
  font-weight: 600;
}

.skills-container {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 15%;
  margin-bottom: 16px;
}

.skill-image {
  width: 60px;
}

.skill-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 180px;
  background: linear-gradient(
    0deg, #001E3D 52%, #003062 98%);
  border-radius: 5px;
  box-shadow: 0px 0px 19px 0px #00000078;
}

.skill-image-4 {
  margin-top: 3px;
  width: 56px;
}

.skil-image-4 {
  margin-top: 7px;
  width: 54px;
}

.skill-name {
  margin: 16px 0;
}

.skill-body {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 360px) {
  .skills-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .skill-box {
    width: 162px;
  }
}

@media only screen and (min-width: 768px) {

  .home-container {
    background-image: linear-gradient( 
2deg , #001E3D 19%, transparent 75%);
  }
  
  .values-container {
    margin-top: 120px;
  }

  .home-welcome {
    width: 34%;
    margin-top: 150px;
  } 

  .welcome-intro {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1034px) {
  .home-container {
    height: auto;
  }

  .skills-container {
    display: flex;
    width: 70%;
    margin-top: 100px;
    justify-content: space-around;
  }

}
